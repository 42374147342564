import React, { useState } from "react";
import { Link } from "react-router-dom";
import logo from "../logo/logo512.png";

const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => setIsMenuOpen(!isMenuOpen);

  return (
    <nav className="navbar">
      <div className="navbar-container">
        <div className="navbar-logo">
          <img src={logo} alt="Logo" />
        </div>
        <span className="navbar-toggle" onClick={toggleMenu}>
          &#9776;
        </span>
        <ul className={`navbar-links ${isMenuOpen ? "active" : ""}`}>
          <li>
            <Link to="/home">Home</Link>
          </li>
          <li>
            <Link to="/about">About</Link>
          </li>
          <li>
            <Link to="/coursecards">Courses</Link>
          </li>
          <li>
            <Link to="/blogs">Blogs</Link> {/* Added Blogs link */}
          </li>
          <li>
            <Link to="/contact">Contact Us</Link>
          </li>
          {/* <li>
            <Link to="/hiringpartners">Hiring Partners</Link>
          </li> */}
          <li>
            <Link to="/cgpa">CGPA Program</Link>
          </li>
        </ul>
      </div>
    </nav>
  );
};

export default Navbar;
