import React from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';

import { Helmet } from 'react-helmet'; // Import react-helmet

import Popup from './components/Popup';
import Navbar from './components/Navbar';
import Home from './components/Home';
import CourseCards from './components/CourseCards';
import CGPA from './components/CGPA';
import FeaturedCourses from './components/FeaturedCourses'; // Retain as it is
import CourseDetail from './components/CourseDetail'; // New component for dynamic course details
import Blogs from './components/Blogs';
import About from './components/About';
import Footer from './components/Footer';
import AlumniPlaced from './components/AlumniPlaced';
import ContactUs from './components/ContactUs';
import './global.css';

function Sitemap() {
  const routes = [
    { loc: '/', changefreq: 'daily', priority: '1.0' },
    { loc: '/home', changefreq: 'daily', priority: '1.0' },
    { loc: '/blogs', changefreq: 'weekly', priority: '0.8' },
    { loc: '/coursecards', changefreq: 'weekly', priority: '0.8' },
    { loc: '/cgpa', changefreq: 'monthly', priority: '0.7' },
    { loc: '/about', changefreq: 'monthly', priority: '0.7' },
    { loc: '/contact', changefreq: 'monthly', priority: '0.7' },
    { loc: '/AlumniPlaced', changefreq: 'monthly', priority: '0.7' },
  ];

  const baseUrl = 'https://www.codinggranth.com';

  const sitemapXml = `<?xml version="1.0" encoding="UTF-8"?>
<urlset xmlns="http://www.sitemaps.org/schemas/sitemap/0.9">
  ${routes
    .map(
      (route) => `
    <url>
      <loc>${baseUrl}${route.loc}</loc>
      <changefreq>${route.changefreq}</changefreq>
      <priority>${route.priority}</priority>
    </url>`
    )
    .join('')}
</urlset>`;

  return (
    <div>
      <Helmet>
        <title>Sitemap</title>
        <meta name="robots" content="noindex, follow" />
        <meta http-equiv="Content-Type" content="application/xml; charset=utf-8" />
      </Helmet>
      <pre>{sitemapXml}</pre>
    </div>
  );
}



function App() {
  const router = createBrowserRouter([
    {
      path: '/',
      element: (
        <>
          <Navbar />
          <Popup/>
          <Home />
          <CourseCards />
          <CGPA />
          <FeaturedCourses />
          <Blogs />
          <About />
          <AlumniPlaced/>
          <ContactUs />
          <Footer />
        </>
      ),
    },
    {
      path: '/home',
      element: (
        <>
          <Navbar />
          <Popup/>
          <Home />
          <CourseCards />
          <CGPA />
          <FeaturedCourses />
          <Blogs />
          <About />
          <AlumniPlaced/>
          <ContactUs />
          <Footer />
        </>
      ),
    },
    {
      path: '/sitemap.xml',
      element: <Sitemap />,
    },
    {
      path: '/blogs',
      element: (
        <>
          <Navbar />
          <Blogs />
          <Footer />
        </>
      ),
    },
    {
      path: '/coursecards',
      element: (
        <>
          <Navbar />
          <FeaturedCourses />
          <Footer />
        </>
      ),
    },
    {
      path: '/coursecards/:courseId', // Dynamic route for course details
      element: (
        <>
          <Navbar />
          <CourseDetail /> {/* This component handles individual course details */}
          <Footer />
        </>
      ),
    },
    {
      path: '/cgpa',
      element: (
        <>
          <Navbar />
          <CGPA />
          <Footer />
        </>
      ),
    },
    {
      path: '/about',
      element: (
        <>
          <Navbar />
          <About />
          <Footer />
        </>
      ),
    },
    {
      path: '/contact',
      element: (
        <>
          <Navbar />
          <ContactUs />
          <Footer />
        </>
      ),
    },
    {
      path: '/AlumniPlaced',
      element: (
        <>
          <Navbar />
          <AlumniPlaced />
          <Footer />
        </>
      ),
    },
  ]);

  return <RouterProvider router={router} />;
}

export default App;





