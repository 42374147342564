import React from "react";
import "../Stylings/CGPA.css";

const CGPA = () => {
  return (
    <div className="cgpa-container">
      <h1 className="cgpa-heading">CGPA<br/>Coding Granth Placement Assurance</h1>
      <br /><br />
      <div className="cgpa-intro">
        <p>
          The "Coding Granth Placement Assurance" program is designed & 
          architectured to provide a comprehensive, industry-relevant curriculum 
          that prepares fresher grads for real-world software development roles.
       
          Our flagship 1-year course ensures not just mastery of programming but 
          also guarantees placement. Our curriculum is designed with input from 
          industry experts to cover the latest programming languages, software 
          development methodologies, and emerging technologies.
          <br />  <br /> 
          The CGPA program will mould your IT career and thrive you to implement 
          code as per industrial standards under the mentorship of expert developers. 
          The program is structured to cover everything from foundational programming 
          concepts to advanced specializations, offering a complete learning journey 
          that culminates in guaranteed placement support. 
        </p>

        {/* Icons Section */}
          <div className="icons-container">
          
          <div className="icon-item">
            <i className="fas fa-calendar-alt"></i>
            <p>1 Year</p>
          </div>
          <div className="icon-item">
            <i className="fas fa-clock"></i>
            <p>180 min</p>
          </div>
          <div className="icon-item">
          <i class='fas fa-chalkboard-teacher'></i>
            <p>Online | Offline | 1:1</p>
          </div>
          <div className="icon-item">
          <i class='fas fa-award'></i>
          <p>100% Placements</p>
          </div>
          <div className="icon-item">
            <i className="fas fa-circle"></i>
            <p>Industrial Projects</p>
          </div>
        </div>

        <button className="learn-more-button">
  Learn More <i className="fas fa-arrow-right"></i>
</button>


      </div>
    </div>
  );
};

export default CGPA;
