import React from "react";
import { FaCalendar, FaClock, FaLaptop, FaUserGraduate } from "react-icons/fa"; // Importing icons
import { useNavigate } from "react-router-dom";

const FeaturedCourses = () => {
  const navigate = useNavigate();

  return (
    <section style={{ padding: "2rem", background: "#fef0ef" }}>
      <h2
        style={{
          textAlign: "center",
          marginBottom: "3rem",
          fontSize: "1.8rem",
          fontFamily: "'Source Serif Pro', serif",
        }}
      >
        Featured Courses
      </h2>

      <div
        style={{
          display: "grid",
          gridTemplateColumns: "repeat(auto-fit, minmax(250px, 1fr))",
          gap: "1rem",
          width: "100%",
          maxWidth: "1200px",
          margin: "0 auto",
        }}
      >
        {featuredcourses.map((course, index) => (
          <div
            key={index}
            style={{
              border: "1px solid #ddd",
              padding: "1rem",
              borderRadius: "5px",
              background: "#fff",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              cursor: "pointer",
              transition: "transform 0.3s ease, box-shadow 0.3s ease",
            }}
            onClick={() => navigate(`/coursecards/${index + 1}`)} // Navigate on click
            onMouseOver={(e) => {
              e.currentTarget.style.transform = "scale(1.05)";
              e.currentTarget.style.boxShadow = "0 8px 16px rgba(0, 0, 0, 0.2)";
            }}
            onMouseOut={(e) => {
              e.currentTarget.style.transform = "scale(1)";
              e.currentTarget.style.boxShadow = "none";
            }}
          >
            <img
              src={course.image}
              alt={course.title}
              style={{
                width: "100%",
                height: "150px",
                objectFit: "cover",
                borderRadius: "5px",
                marginBottom: "1rem",
              }}
            />
            <h3 style={{ fontSize: "1.2rem", marginBottom: "0.5rem" }}>{course.title}</h3>
            <p style={{ fontSize: "0.9rem", marginBottom: "0.5rem" }}>{course.description}</p>
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr",
                gap: "0.5rem",
                marginTop: "1rem",
              }}
            >
              <p style={iconStyle}>
                <FaCalendar style={{ color: "#f59c18", marginRight: "0.5rem" }} />
                {course.duration}
              </p>
              <p style={iconStyle}>
                <FaLaptop style={{ color: "#f59c18", marginRight: "0.5rem" }} />
                {course.mode}
              </p>
              <p style={iconStyle}>
                <FaClock style={{ color: "#f59c18", marginRight: "0.5rem" }} />
                {course.time}
              </p>
              <p style={iconStyle}>
                <FaUserGraduate style={{ color: "#f59c18", marginRight: "0.5rem" }} />
                {course.sessions}
              </p>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

// Style for the icons and rows
const iconStyle = {
  display: "flex",
  alignItems: "center",
  fontSize: "0.9rem",
};

export default FeaturedCourses;

const featuredcourses = [
  {
    title: "React",
    description: "Mastering React with Coding Granth enables you to build dynamic, interactive web applications and excel in frontend development.",
    duration: "6 weeks",
    mode: "Online/Offline",
    time: "90 mins",
    sessions: "5 sessions/week",
    image: require("../assets/react.jpeg"), // Update with the correct path to your React image
  },
  {
    title: "Angular",
    description: "Mastering Angular with Coding Granth empowers you to create scalable, high-performance web apps and succeed as a frontend developer.",
    duration: "6 weeks",
    mode: "Online/Offline",
    time: "90 mins",
    sessions: "5 sessions/week",
    image: require("../assets/angular.png"), // Update with the correct path to your Angular image
  },
  {
    title: "Python",
    description: "Mastering Python with Coding Granth empowers you to develop versatile solutions and excel in software development.",
    duration: "8 weeks",
    mode: "Online/Offline",
    time: "60 mins",
    sessions: "5 sessions/week",
    image: require("../assets/python.png"), // Update with the correct path to your Python image
  },
  {
    title: "Rust",
    description: "Mastering Rust with Coding Granth equips you to build fast, reliable systems and advance your programming career.",
    duration: "8 weeks",
    mode: "Online/Offline",
    time: "90 mins",
    sessions: "5 sessions/week",
    image: require("../assets/rust.jpg"), // Update with the correct path to your Rust image
  },
  {
    title: "PostgreSQL",
    description: "Mastering PostgreSQL with Coding Granth strengthens your database management skills and helps you build robust, efficient systems.",
    duration: "3 weeks",
    mode: "Online/Offline",
    time: "60 mins",
    sessions: "5 sessions/week",
    image: require("../assets/postgres.png"), // Update with the correct path to your PostgreSQL image
  },
  {
    title: "Data Science",
    description: "Mastering Data Science with Coding Granth equips you to unlock the power of data and drive informed decision-making in any field.",
    duration: "8 weeks",
    mode: "Online/Offline",
    time: "60 mins",
    sessions: "5 sessions/week",
    image: require("../assets/datasci.jpg"), // Update with the correct path to your Data Science image
  },
  {
    title: "C Programming",
    description: "Mastering C with Coding Granth lays the foundation for efficient programming and opens doors to system-level development.",
    duration: "4 weeks",
    mode: "Online/Offline",
    time: "60 mins",
    sessions: "5 sessions/week",
    image: require("../assets/c.png"), // Update with the correct path to your C Programming image
  },
  {
    title: "C++",
    description: "Mastering C++ with Coding Granth sharpens your skills in object-oriented programming and prepares you for high-performance applications",
    duration: "8 weeks",
    mode: "Online/Offline",
    time: "90 mins",
    sessions: "5 sessions/week",
    image: require("../assets/cpp.jpeg"), // Update with the correct path to your C++ image
  },
];
