import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom"; // Import useNavigate
import "../Stylings/Popup.css"; // Import the styles
import javaImage from "../assets/java.png"; // Add path for Java image
import springBootImage from "../assets/spring-boot.png"; // Add path for Spring Boot image

const Popup = () => {
  const [showPopup, setShowPopup] = useState(false); // State to control popup visibility
  const [notificationVisible, setNotificationVisible] = useState(false); // For notification icon
  const navigate = useNavigate(); // Initialize the useNavigate hook

  const courses = [
    {
      title: "Java & Java 8",
      description:
        "Unlock the Power of Java 8 with Coding Granth. At Coding Granth, our Java training program is designed to equip learners with the in-depth knowledge and practical skills needed to build robust, scalable applications using Java 8 features.",
      duration: "7th January 2025",
      mode: "Online | Offline",
      image: javaImage, // Add image for Java
    },
    {
      title: "Spring Boot",
      description:
        "At Coding Granth, our Spring Boot training program is crafted to empower learners with comprehensive knowledge and hands-on expertise to develop robust, scalable applications. Dive deep into Java 8 features and learn how to harness them effectively in building modern, enterprise-grade applications with Spring Boot.",
      duration: "7th January 2025",
      mode: "Online | Offline",
      image: springBootImage, // Add image for Spring Boot
    },
  ];

  // Show the popup after 15 seconds automatically
  useEffect(() => {
    const timer = setTimeout(() => {
      setShowPopup(true); // Show the popup after 15 seconds
      setNotificationVisible(true); // Make the notification bell visible
    }, 15000);

    return () => clearTimeout(timer); // Cleanup timer when component unmounts
  }, []);

  // Close the popup
  const handleClose = () => {
    setShowPopup(false); // Hide the popup
  };

  // Show the notification popup when bell is clicked
  const handleShowNotification = () => {
    setShowPopup(true); // Show the popup manually when bell is clicked
  };

  // Navigate to the ContactUs page when "Book a seat" is clicked
  const handleBookSeat = () => {
    navigate('/contact'); // Redirect to ContactUs page
  };

  return (
    <>
      {/* Notification Icon (Persistent) */}
      {notificationVisible && (
        <div className="notification-icon" onClick={handleShowNotification}>
          🛎️
        </div>
      )}

      {/* Popup Notification */}
      {showPopup && (
        <div className="popup-overlay">
          <div className="popup-container">
            <button className="popup-close" onClick={handleClose}>
              ✖
            </button>
            <h2 className="popup-header">Upcoming Batch</h2>
            <div className="popup-cards">
              {courses.map((course, index) => (
                <div className="popup-card" key={index}>
                  <img
                    src={course.image} // Image source
                    alt={course.title} // Alt text for the image
                  />
                  <div className="popup-card-content">
                    <h3>{course.title}</h3>
                    <p>{course.description}</p>
                    <p style={{ marginTop: "10px" }}>
                      <strong>📅</strong> <span className="bold-date">{course.duration}</span>
                    </p>
                    <p>
                      <strong>💻</strong> {course.mode}
                    </p>
                  </div>
                </div>
              ))}
            </div>
            <button className="popup-book-button" onClick={handleBookSeat}>
              Book a seat
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default Popup;
