import React from 'react';
import "../global.css"; // Ensure global styles are included

const Footer = () => (
  <footer className="footer">
    <div className="footer-container">
      <div className="footer-logo">
        <h3>Coding Granth</h3>
      </div>

      <div className="footer-links">
        <ul>
          <li><a href="/">Home</a></li>
          <li><a href="/about">About</a></li>
          <li><a href="/coursecards">Courses</a></li>
          <li><a href="/contact">Contact</a></li>
        </ul>
      </div>

      <div className="footer-social">
        <ul>
          <li><a href="https://www.facebook.com/share/15MMHbsZFV/" target="_blank" rel="noopener noreferrer">Facebook</a></li>
          <li><a href="https://www.instagram.com/coding.granth/profilecard/?igsh=MXpsNjY3bDFidXdl" target="_blank" rel="noopener noreferrer">Instagram</a></li>
          <li><a href="https://www.linkedin.com/company/coding-granth/" target="_blank" rel="noopener noreferrer">LinkedIn</a></li>
        </ul>
      </div>
    </div>

    <div className="footer-bottom">
      <p>&copy; {new Date().getFullYear()} Coding Grath. All rights reserved.</p>
    </div>
  </footer>
);

export default Footer;
