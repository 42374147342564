import React from "react";
import { useParams, useNavigate } from "react-router-dom";

const courses = [
  {
    id: "#",
    title: "Data Structures Using Java",
    description: `Data Structures and Algorithms (DSA) form the foundation of efficient programming. Understanding DSA is crucial for:
1. Problem-Solving Skills: DSA enhances logical thinking and analytical skills, enabling you to approach complex problems systematically.
2. Efficiency: Knowledge of data structures helps in selecting the right structure for specific tasks, optimizing both time and space complexity.
3. Job Preparedness: Many technical interviews focus on DSA. Mastering these concepts significantly increases employability in software development roles.
4. Foundation for Advanced Topics: A strong grasp of DSA is essential for understanding advanced computer science topics, such as databases, networking, and system design.`,
    duration: {
      courseDuration: "12 weeks",
      classesPerWeek: "5 sessions per week",
      sessionLength: "90 mins per session",
    },
    prerequisites: [
      "Basic understanding of any programming languages",
      "Enthusiasm for learning and problem-solving",
    ],
    modeOfDelivery: ["Online", "Offline", "1:1"],
    syllabus: [
      {
        heading: "1. Introduction to Java",
        topics: [
          "Java Basics: Syntax, Control Structures, and Object-Oriented Concepts",
          "Java Collections Framework",
        ],
      },
      {
        heading: "2. Fundamental Data Structures",
        topics: [
          "Arrays",
          "Linked Lists (Singly and Doubly)",
          "Stacks",
          "Queues",
          "Hash Tables",
        ],
      },
      {
        heading: "3. Advanced Data Structures",
        topics: [
          "Trees (Binary Trees, Binary Search Trees, AVL Trees, Heaps)",
          "Graphs (Representation, Traversal Algorithms)",
        ],
      },
      {
        heading: "4. Algorithms",
        topics: [
          "Sorting Algorithms (Quick Sort, Merge Sort, Bubble Sort)",
          "Searching Algorithms (Linear Search, Binary Search)",
          "Recursion and Backtracking",
          "Dynamic Programming Basics",
        ],
      },
      {
        heading: "5. Complexity Analysis",
        topics: ["Time and Space Complexity", "Big O Notation"],
      },
      {
        heading: "6. Practical Applications",
        topics: [
          "Real-world problems using DSA",
          "Coding challenges and competitions",
        ],
      },
    ],
  },
  { id: "#", title: "Web Developer", description: "Master front-end and back-end web development." },
  { id: "#", title: "Java Developer", description: "Learn advanced Java programming techniques." },
  { id: "#", title: "Database Management", description: "Explore database systems and SQL queries." },
  { id: "#", title: "Cloud Computing", description: "Master cloud platforms and architectures." },
  { id: "#", title: "AI & Machine Learning", description: "Dive into AI concepts and machine learning models." },
  { id: "#", title: "Cyber Security", description: "Understand ethical hacking and network security." },
  { id: "#", title: "DevOps", description: "Learn CI/CD pipelines and infrastructure automation." },
];

const CourseDetail = () => {
  const { courseId } = useParams();
  const navigate = useNavigate();

  const course = courses.find((c) => c.id === courseId);

  if (!course) {
    return (
      <div style={{ textAlign: "center", marginTop: "2rem" }}>
        <h2>Upgraded course syllabus launching soon. For more details kindly contact on +91 80556 73378</h2>
        <button
          style={{
            marginTop: "1rem",
            padding: "0.5rem 1rem",
            backgroundColor: "#007BFF",
            color: "#fff",
            border: "none",
            cursor: "pointer",
          }}
          onClick={() => navigate("/coursecards")}
        >
          Please click here to navigate back to courses.
        </button>
      </div>
    );
  }

  return (
    <div style={{ padding: "2rem", fontFamily: "Arial, sans-serif" }}>
      {/* Top Image Section */}
      <div style={{ textAlign: "center", marginBottom: "2rem" }}>
        <img
          src={`https://via.placeholder.com/800x200?text=${course.title}`}
          alt={`${course.title}`}
          style={{
            width: "100%",
            height: "auto",
            maxWidth: "800px",
            border: "2px solid #000",
          }}
        />
      </div>

      {/* Content Section */}
      <div style={{ display: "flex", gap: "2rem" }}>
        {/* Left Section: Course Heading, Details, Duration, Prerequisites, Mode of Delivery */}
        <div style={{ flex: 1 }}>
          <h1 style={{ marginBottom: "1rem", fontSize: "2rem" }}>{course.title}</h1>
          <h3 style={{ marginBottom: "1rem", color: "#555" }}>Course Details</h3>
          <p style={{ whiteSpace: "pre-wrap", lineHeight: "1.6" }}>{course.description}</p>

          {/* Duration */}
          <h3 style={{ margin: "1rem 0 0.5rem", color: "#555" }}>Duration</h3>
          <ul style={{ listStyleType: "disc", paddingLeft: "20px", lineHeight: "1.6" }}>
            <li>Course Duration: {course.duration.courseDuration}</li>
            <li>Classes: {course.duration.classesPerWeek}</li>
            <li>Session Length: {course.duration.sessionLength}</li>
          </ul>

          {/* Prerequisites */}
          <h3 style={{ margin: "1rem 0 0.5rem", color: "#555" }}>Prerequisites</h3>
          <ul style={{ listStyleType: "disc", paddingLeft: "20px", lineHeight: "1.6" }}>
            {course.prerequisites.map((item, index) => (
              <li key={index}>{item}</li>
            ))}
          </ul>

          {/* Mode of Delivery */}
          <h3 style={{ margin: "1rem 0 0.5rem", color: "#555" }}>Mode of Delivery</h3>
          <ul style={{ listStyleType: "disc", paddingLeft: "20px", lineHeight: "1.6" }}>
            {course.modeOfDelivery.map((mode, index) => (
              <li key={index}>{mode}</li>
            ))}
          </ul>
        </div>

        {/* Right Section: Course Syllabus */}
        <div style={{ flex: 1 }}>
          <h3 style={{ marginBottom: "1rem", color: "#555" }}>Course Syllabus</h3>
          {course.syllabus ? (
            <div>
              {course.syllabus.map((section, index) => (
                <div key={index} style={{ marginBottom: "1rem" }}>
                  <h4 style={{ fontWeight: "bold" }}>{section.heading}</h4>
                  <ul style={{ listStyleType: "circle", paddingLeft: "20px", lineHeight: "1.6" }}>
                    {section.topics.map((topic, topicIndex) => (
                      <li key={topicIndex}>{topic}</li>
                    ))}
                  </ul>
                </div>
              ))}
            </div>
          ) : (
            <p>Syllabus information is not available.</p>
          )}
        </div>
      </div>

      {/* Buttons Section */}
      <div style={{ marginTop: "2rem", textAlign: "center" }}>
        <button
          style={{
            backgroundColor: "#007BFF",
            color: "#fff",
            padding: "0.5rem 1rem",
            border: "none",
            cursor: "pointer",
            marginRight: "1rem",
            fontSize: "1rem",
          }}
          onClick={() => alert("Get Free Live Session!")}
        >
          Get Free Live Session
        </button>
        <button
          style={{
            backgroundColor: "#28A745",
            color: "#fff",
            padding: "0.5rem 1rem",
            border: "none",
            cursor: "pointer",
            fontSize: "1rem",
          }}
          onClick={() => navigate("/contact")}
        >
          Enquire Now
        </button>
      </div>
    </div>
  );
};

export default CourseDetail;
