import React from "react";
import "../global.css";

const Home = () => {
  return (
    <section className="home">
      <div className="home-overlay">
        <div className="home-content">
          
        <h1>
            <span className="heading-line">Coding</span>
            <span className="heading-line">Granth</span>
          </h1>

          {/* Updated search bar */}
          <div className="search-bar">
            <input
              type="text"
              placeholder="Search tutorials, articles, or topics..."
              className="search-input"
            />
            <button className="search-button">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={2}
                stroke="currentColor"
                className="search-icon"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M21 21l-4.35-4.35M10 18a8 8 0 100-16 8 8 0 000 16z"
                />
              </svg>
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Home;
