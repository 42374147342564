// Location icon by Those Icons, used under [free license], from [https://www.flaticon.com/].
// Phone icon by Kiranshastry, used under [free license], from [https://www.flaticon.com/].
// Mail icon by  meaicon, used under [free license], from [https://www.flaticon.com/].
// Facebook icon by Hight Quality, used under [free license], from [https://www.flaticon.com/].
// X(Twitter) icon by  Freepik, used under [free license], from [https://www.flaticon.com/].
// Linkedin icon by  Md Tanvirul Haque, used under [free license], from [https://www.flaticon.com/].
// Instagram icon by  FreePik, used under [free license], from [https://www.flaticon.com/].

import React, { useState } from "react";
import "../Stylings/ContactUs.css";

const ContactUs = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
    phone: "", // New field for phone number
    countryCode: "+91", // Default country code for India
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    alert("Form Submitted! Thank you for contacting us.");
  };

  return (
    <div className="contact-us-container">
      {/* Header Section */}
      <h1 className="contact-title">Contact Us</h1>
      <p className="contact-description">
        Hello there! Got questions or just want to say hi? Drop us a message,
        and we'll get back to you as soon as possible. Let’s stay connected!
      </p>

      {/* Main Content */}
      <div className="contact-content">
        {/* Left Section (Image) */}
        <div className="contact-image"></div>

        {/* Middle Section (Get In Touch) */}
        <div className="get-in-touch">
          <h2>Get In Touch</h2>
          <p>
            Reach us at the following address, phone, or email.
            <br />
            We're here to help!
          </p>
          <ul className="contact-details">
            <li>
              <img
                src="https://cdn-icons-png.flaticon.com/128/484/484167.png"
                alt="Location"
                className="icon"
              />
              Office No. 61, 3rd floor, C wing, Shreenath Plazza, Dnyaneshwar
              Paduka Chowk, FC Road, Shivaji Nagar Pune – 411005
            </li>
            <li>
              <img
                src="https://cdn-icons-png.flaticon.com/128/16341/16341536.png"
                alt="Phone"
                className="icon"
              />
              +91 8055673378 / +91 7796484784
            </li>
            <li>
              <img
                src="https://cdn-icons-png.flaticon.com/128/712/712040.png"
                alt="Email"
                className="icon"
              />
              codinggranth@gmail.com
            </li>
          </ul>
          <div className="social-media">
            <a
              href="https://www.facebook.com/share/15MMHbsZFV/"
              className="social-icon"
            >
              <img
                src="https://cdn-icons-png.flaticon.com/128/2175/2175193.png"
                alt="Facebook"
              />
            </a>

            <a
              href="https://www.linkedin.com/company/coding-granth/"
              className="social-icon"
            >
              <img
                src="https://cdn-icons-png.flaticon.com/128/3669/3669739.png"
                alt="LinkedIn"
              />
            </a>
            <a
              href=" https://www.instagram.com/coding.granth/profilecard/?igsh=MXpsNjY3bDFidXdl "
              className="social-icon"
            >
              <img
                src="https://cdn-icons-png.flaticon.com/128/1384/1384015.png"
                alt="Instagram"
              />
            </a>
          </div>
        </div>

        {/* Right Section (Send a Message) */}
        <div className="send-message-container">
          <h2>Send a Message</h2>
          <form onSubmit={handleSubmit} className="contact-form">
            <div className="form-group">
              <input
                type="text"
                name="name"
                placeholder="Name"
                value={formData.name}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group">
              <input
                type="email"
                name="email"
                placeholder="E-mail address"
                value={formData.email}
                onChange={handleChange}
                required
              />
            </div>
            <div className="form-group">
              <div className="phone-input">
                <select
                  name="countryCode"
                  value={formData.countryCode}
                  onChange={handleChange}
                >
                  <option value="+91">+91 (India)</option>
                  <option value="+1">+1 (USA)</option>
                  <option value="+44">+44 (UK)</option>
                  {/* Add more country options as needed */}
                </select>
                <input
                  type="text"
                  name="phone"
                  placeholder="Your Phone Number"
                  value={formData.phone}
                  onChange={handleChange}
                  required
                />
              </div>
            </div>
            <div className="form-group">
              <textarea
                name="message"
                placeholder="Message"
                value={formData.message}
                onChange={handleChange}
                required
              />
            </div>

            <button type="submit" className="submit-btn">
              Submit
            </button>
          </form>
        </div>
      </div>

      {/* Map Section */}
      <div className="map-section">
        <iframe
          title="Google Maps"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3783.057435338558!2d73.83992867523696!3d18.52630648256835!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bc2c07f85464493%3A0x4415f79dee1766d8!2sShreenath%20Plaza%2C%20Sud%20Nagar%2C%20Shivajinagar%2C%20Pune%2C%20Maharashtra%20411005!5e0!3m2!1sen!2sin!4v1734015644532!5m2!1sen!2sin"
          style={{ border: 0 }}
          allowFullScreen=""
          loading="lazy"
        ></iframe>
      </div>
    </div>
  );
};

export default ContactUs;
