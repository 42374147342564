import React from "react";
import "../Stylings/About.css"; // CSS for styling the layout
import missionImage from "../assets/mission.png"; // Replace with your image path
import studentsImage from "../assets/students.jpg"; // Replace with your image path
import educationImage from "../assets/education.png"; // Replace with your image path

const About = () => {
  return (
    <div className="about-us-container">
      {/* Main Heading Section */}
      <div className="main-heading">
        <h1>Pioneers in Ed-Tech Industry  </h1>
      </div>

      {/* Mission Section */}
      <div className="about-row">
        <div className="about-text">
          <h2 className="section-heading">A Societal Vision</h2>
          <p className="section-paragraph">
          To transform aspiring learners into skilled tech leaders who innovate, inspire, and shape the future through coding.
          </p>
        </div>
        <div className="about-image">
        <img src={missionImage} alt="Mission" className="section-image" loading="lazy" />
        </div>
      </div>

      {/* Excellence Section */}
      <div className="about-row">
        <div className="about-image">
          <img src={studentsImage} alt="Students walking" className="section-image" loading="lazy" />
        </div>
        <div className="about-text">
          <h3 className="excellence-subheading">Mission</h3>
          <p className="excellence-paragraph">
          To nurture the next generation of tech leaders through practical, cutting-edge coding training that inspires innovation, creativity and problem-solving.
          </p>
        </div>
      </div>

      <div className="about-row">
        <div className="about-text">
          <h3 className="excellence-subheading">Educating Tomorrow's Innovators</h3>
          <p className="excellence-paragraph">
          At Coding Granth, we are dedicated to preparing the next generation of innovators by providing top-notch education in coding and software development. Our institute focuses on practical learning, real-world applications, and fostering creativity to ensure students are ready to excel in the rapidly evolving tech industry. With a commitment to empowering individuals, we aim to inspire and nurture the innovators of tomorrow.
          </p>
        </div>
        <div className="about-image">
          <img src={educationImage} alt="Innovative learning" className="section-image" loading="lazy" />
        </div>
      </div>

      <div className="about-row center">
  <button className="learn-more-button">
    Learn More  <i className="fas fa-arrow-right"></i>
  </button>
</div>

    </div>
  );
};

export default About;
