import React from "react";
import '../Stylings/Blogs.css'

const blogsData = [
  {
    title: "Food, Clothing, Shelter, and Now... Coding: The New Essential Skill",
    author: "Yash Salunkhe",
    location: "Pune, India",
    //image: "https://cdn-icons-png.flaticon.com/128/4140/4140048.png",
    image: require("../assets/yash_salunkhe.jpg"),
    // Replace with actual image URL
    description: "For centuries, human needs have revolved around the three basics: food, clothing, and shelter. These essentials have been fundamental to our survival and well-being, serving as the foundation upon which civilizations have been built.",
  },
  {
    title: "Roadmap to IT Industry",
    author: "Srikant Ganesh",
    location: "New Jersey, US",
    // image: "https://cdn-icons-png.flaticon.com/128/4140/4140048.png", // Replace with actual image URL
    image: require("../assets/srikant_ganesh.jpg"),
    description: "The Information Technology (IT) industry is a powerhouse of innovation, offering exciting career opportunities. Whether you're a student, a career switcher, or an experienced professional looking to expand your skill set, a clear roadmap can guide you toward success in the IT domain.",
  },
  {
    title: "IT developers cum mentors",
    author: "Manik Tambulkar",
    location: "Pune, India",
   // image: "https://cdn-icons-png.flaticon.com/128/4140/4140061.png", // Replace with actual image URL
   image: require("../assets/manik_tambulkar.jpg"),
    description: "In the dynamic world of IT, there’s a growing need for professionals who can not only develop cutting-edge software but also mentor aspiring developers. These dual-role professionals, often referred to as IT Developers cum Mentors, bring a unique blend of technical expertise and leadership skills to the table.",
  },
];

const Blogs = () => {
  return (
    <div className="blogs-page">
      <h2 className="blogs-title">Our Latest Blogs</h2>
      <div className="blogs-container">
        {blogsData.map((blog, index) => (
          <div key={index} className="blog-card">
            <img src={blog.image} alt={blog.title} className="blog-image" />
            <h3 className="blog-author">{blog.author}</h3>
            <p className="blog-location">{blog.location}</p>
            <h4 className="blog-title">{blog.title}</h4>
            <p className="blog-description">{blog.description}</p>
          </div>
        ))}
      </div>
      <a href="/blogs" className="more-blogs-link">
        More Blogs →
      </a>
    </div>
  );
};

export default Blogs;
