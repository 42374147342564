import React, { useState } from "react";
import  '../global.css';

const courses = [
  {
    title: "DSA",
    description: "Mastering DSA with Coding Granth sharpens your programming skills and helps you crack tech interviews with ease.",
    duration: "6 weeks",
    mode: "Online/Offline",
    time: "120 mins",
    sessions: "5 sessions/week",
    image: require("../assets/dsa.jpg"), // Update with the correct path to your DSA image
  },
  {
    title: "Java & Java 8",
    description: "Mastering Java and Java 8 with Coding Granth empowers you to unlock advanced programming skills and boost your career prospects.",
    duration: "6 weeks",
    mode: "Online/Offline",
    time: "120 mins",
    sessions: "5 sessions/week",
    image: require("../assets/j.jpeg"), // Update with the correct path to your Java image
  },
  {
    title: "Full Stack Development",
    description: "Mastering Full Stack Development with Coding Granth prepares you to build complete, dynamic applications and thrive in the tech industry",
    duration: "20 weeks",
    mode: "Online/Offline",
    time: "120 mins",
    sessions: "5 sessions/week",
    image: require("../assets/fullstack.png"), // Update with the correct path to your Full Stack image
  },
  {
    title: "Spring Boot",
    description: "Mastering Spring Boot with Coding Granth equips you to build scalable applications and accelerate your development career.",
    duration: "6 weeks",
    mode: "Online/Offline",
    time: "90 mins",
    sessions: "5 sessions/week",
    image: require("../assets/spring-boot.png"), // Update with the correct path to your Spring Boot image
  },
];

const CourseCards = () => {
  const [courseList, setCourseList] = useState(courses);

  const handleNext = () => {
    const updatedList = [...courseList];
    const firstCard = updatedList.shift();
    updatedList.push(firstCard);
    setCourseList(updatedList);
  };

  const handlePrev = () => {
    const updatedList = [...courseList];
    const lastCard = updatedList.pop();
    updatedList.unshift(lastCard);
    setCourseList(updatedList);
  };

  return (
    <section
      style={{
        padding: "2rem",
        background: "#fef0ef",
        overflowX: "hidden",
      }}
    >
      <h2
        style={{
          textAlign: "center",
          marginBottom: "3rem",
          fontSize: "1.8rem",
          fontFamily: "'Source Serif Pro', serif",
        }}
      >
        Trending Courses
      </h2>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: "1rem",
        }}
      >
        <button
          onClick={handlePrev}
          style={{
            background: "#f59c18",
            color: "#fff",
            border: "none",
            padding: "0.5rem 1rem",
            borderRadius: "5px",
            cursor: "pointer",
          }}
        >
          &lt;
        </button>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(auto-fit, minmax(250px, 1fr))",
            gap: "1rem",
            width: "100%",
            maxWidth: "1200px",
          }}
        >
          {courseList.slice(0, 4).map((course, index) => (
            <div
              key={index}
              style={{
                border: "1px solid #ddd",
                padding: "1rem",
                borderRadius: "5px",
                background: "#fff",
                textAlign: "center",
                transition: "transform 0.3s ease, box-shadow 0.3s ease",
              }}
              onMouseOver={(e) => {
                e.currentTarget.style.transform = "scale(1.05)";
                e.currentTarget.style.boxShadow =
                  "0 8px 16px rgba(0, 0, 0, 0.2)";
              }}
              onMouseOut={(e) => {
                e.currentTarget.style.transform = "scale(1)";
                e.currentTarget.style.boxShadow = "none";
              }}
            >
              <img
                src={course.image}
                alt={course.title}
                style={{
                  width: "100%",
                  height: "150px",
                  objectFit: "cover",
                  borderRadius: "5px",
                  marginBottom: "1rem",
                }}
              />
              <h3>{course.title}</h3>
              <p>{course.description}</p>
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "1fr 1fr",
                  gap: "0.5rem",
                  marginTop: "1rem",
                }}
              >
                <p style={iconStyle}>
                  <i
                    className="fa fa-calendar"
                    style={{ color: "#f59c18", marginRight: "0.5rem" }}
                  ></i>
                  {course.duration}
                </p>
                <p style={iconStyle}>
                  <i
                    className="fa fa-laptop"
                    style={{ color: "#f59c18", marginRight: "0.5rem" }}
                  ></i>
                  {course.mode}
                </p>
                <p style={iconStyle}>
                  <i
                    className="fa fa-clock"
                    style={{ color: "#f59c18", marginRight: "0.5rem" }}
                  ></i>
                  {course.time}
                </p>
                <p style={iconStyle}>
                  <i
                    className="fa fa-user-graduate"
                    style={{ color: "#f59c18", marginRight: "0.5rem" }}
                  ></i>
                  {course.sessions}
                </p>
              </div>
            </div>
          ))}
        </div>
        <button
          onClick={handleNext}
          style={{
            background: "#f59c18",
            color: "#fff",
            border: "none",
            padding: "0.5rem 1rem",
            borderRadius: "5px",
            cursor: "pointer",
          }}
        >
          &gt;
        </button>
      </div>
    </section>
  );
};

// Style for the icon rows
const iconStyle = {
  display: "flex",
  alignItems: "center",
  fontSize: "0.9rem",
};

export default CourseCards;
